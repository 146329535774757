<script setup>
/**
 * Footer layout
 *  this component will display 
 *    - copyright
 *    - common link
 *    - social network icon
 */

import Wrap from '@/components/container/WrapComp.vue';
import {ref} from 'vue';
import {frontUrl} from '@/utilities/url.js';


const getYear = ref(new Date().getFullYear());
const links = ref([
  {
    title: 'นโยบายการใช้งาน',
    slug: '/term-of-use'
  },
  {
    title: 'ติดต่อเรา',
    slug: '/contact'
  },
])
const socialLinks = ref([
  {
    title: 'ติดต่อเราผ่านทาง facebook',
    image: 'facebook.svg'
  },
  {
    title: 'ติดต่อเราผ่านทาง line',
    image: 'line.svg'
  },
  {
    title: 'ติดต่อเราผ่านทาง instagram',
    image: 'instagram.svg'
  },
])
</script>


<template>
  <footer class="footer">
    <Wrap>
      <div class="copyright">
        &copy;  2020 - {{ getYear }} Beaverman Co., Ltd.
      </div>
      
      <ul class="footer__nav">
        <li
          v-for="link in links"
          :key="link.slug"
        >
          <a 
            :href="frontUrl(link.slug)" 
            :title="link.title"
          >
            {{ link.title }}
          </a>
        </li>
      </ul>

      <ul class="footer__social">
        <li
          v-for="link in socialLinks"
          :key="link.title"
        >
          <a href="#" :title=" link.title" >
            <img 
              :src=" require('@/assets/images/footer/' + link.image) " 
              :alt=" link.title "
            />
          </a>
        </li>
      </ul>
    </Wrap>
  </footer>
</template>
