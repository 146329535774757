<script setup>
/**
 * HeaderMemberComp
 *    Detect 'logged in' status for change display
 *    if app had logged in status
 *      - show Post new Job button
 *      - if member isn't Contractor, display 'Register Contractor'
 *      - Ring icon for notification
 *      - Chat icon
 *      - Current member name and submenu
 *    if no logged in status, display Log in and Sign up navigator
 */
import SVGIcon from '@/components/display/icon/SVGIconComp.vue';
import { ref, onMounted, computed } from 'vue'; //
import { useRoute } from 'vue-router';


const route = useRoute();
const loaded = ref(false);
const isLogin = computed(() => {
  return route.name === undefined || route.name === 'LoginView';
});

onMounted(() => {
  loaded.value = true;
})

</script>

<template>
  <div 
    v-if="loaded"
    class="header__login"
  >
    <ul>
      <li v-if="isLogin">
        <router-link 
          to="/register/customer"
          class="btn btn--active"
        >
          <SVGIcon icon="user" />
          สมัครเป็นผู้ใข้งาน
        </router-link>
      </li>
      <!-- <li v-if="isLogin">
        <router-link 
          to="/register/contractor"
          class="btn btn--border"
        >
          <SVGIcon icon="helmet" />
          สมัครผู้รับเหมา
        </router-link>
      </li> -->
      <li v-if="!isLogin">
        <router-link 
          to="/login"
          class="btn btn--active"
        >
          <SVGIcon icon="log" />
          เข้าสู่ระบบ
        </router-link>
      </li>
  
      <!-- Chat status -->
      <!-- <li class="header__member__item">
        <a href="#" >
          <SVGIcon icon="bell" />
        </a>
      </li> -->
    </ul>
  </div>
</template>