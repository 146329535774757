<script setup>
/**
 * Header layout
 *  this component will display 
 *    - Beaverman Logo
 *    - Official website manu
 *    - Member login or member navigator
 */

import Wrap from '@/components/container/WrapComp.vue';
import NavLoggedIn from '@/components/layout/HeaderNavLoggedInComp.vue';
import NavLogIn from '@/components/layout/HeaderNavLoginComp.vue';
import { ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { frontUrl } from '@/utilities/url.js';
import { mainNav } from '@/vars/theme/navigator';


const store = useStore();
const showMobileNav = ref(false);
const avatarPath = ref(store.getters.currentUser.avatar);
const links = ref(mainNav)


/** Cycle and Watch ------------ */
watchEffect(() => {
  avatarPath.value = store.getters.currentUser.avatar;
})


/** Method ------------ */
const hasLoggedInStatus = () => {
  return store.getters.loggedIn;
}
// const hasNoti = () => {
//   return false;
// }
// const hasChat = () => {
//   return false;
// }
// const toggleSubmenu = () => {
//   showSubmenu.value = !showSubmenu.value;
// }
// const clickOutsideHandled = () => {
//   showSubmenu.value = false;
// }

const toggleMobileNav = () => {
  showMobileNav.value = !showMobileNav.value;
}

</script>


<template>
  <header class="header">
    <Wrap>
      <a href="//www.beaverman.com" class="header--logo">
        <img src="@/assets/images/logo.svg">
      </a>

      <NavLoggedIn v-if=" hasLoggedInStatus() " />
      <NavLogIn v-else />


      <div class="header__nav">
        <ul class="header__nav__main">
          <li
            v-for="link in links"
            :key="link.slug"
          >
            <a 
              :href="frontUrl(link.slug)" 
              :title="link.title"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
      <a 
        @click.prevent="toggleMobileNav"
        href="#" 
        class="mobile-toggle"
      >
        <img src="@/assets/images/icon/menu.svg" />
      </a>
    </Wrap>
  </header>

  <Teleport v-if="showMobileNav" to="#body">
    <div class="mobile__nav">
      <transition name="mnav__panel">
        <div class="mobile__nav__panel">
          <div class="mobile__nav__toggle">
            <a 
              @click.prevent="toggleMobileNav"
              href="#" 
            >
              <img src="@/assets/images/icon/close-alt.svg" />
            </a>
          </div>
          <ul class="mobile__nav__main">
            <li
              v-for="link in links"
              :key="link.slug"
            >
              <a 
                :href="frontUrl(link.slug)" 
                :title="link.title"
              >
                {{ link.title }}
              </a>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </Teleport>
</template>


<style scoped>
.mobile__nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.8);
  z-index: 200000;
  overflow: auto;
}
.mobile__nav__panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  max-width: 90%;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,0.8);
}

.mobile__nav__toggle {
  margin: 20px;
  text-align: right;
}
.mobile__nav__toggle img {
  display: inline-block;
  width: 24px !important;
  height: 24px;
}
.mobile__nav__main {
  margin: 20px;
  font-size: 20px;
  line-height: 30px;
  font-weight: normal;
}
.mobile__nav__main li {
  margin: 10px 0;
}
.mobile__nav__main a {
  color: #222;
}
</style>