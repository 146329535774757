<script setup>
/**
 * HeaderMemberComp
 *    Detect 'logged in' status for change display
 *    if app had logged in status
 *      - show Post new Job button
 *      - if member isn't Contractor, display 'Register Contractor'
 *      - Ring icon for notification
 *      - Chat icon
 *      - Current member name and submenu
 *    if no logged in status, display Log in and Sign up navigator
 */
import Avatar from '@/components/display/image/AvatarComp.vue';
import SVGIcon from '@/components/display/icon/SVGIconComp.vue';
import Nav from '@/components/display/NavigatorComp.vue';
import { onMounted, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { http } from '@/utilities/http';
import { useRouter } from 'vue-router';

/** 
 * Variables
 */
const store = useStore();
const router = useRouter();
const loaded = ref(false);
const memberId = ref(store.getters.currentUser.member_id);
const memberAvatar = ref(store.getters.currentUser.avatar);
const showMemberMenuStatus = ref(false);
const showNotiStatus = ref(false);
const notiData = ref([]);
const notiUnreadCount = ref(0);



/** 
 * Life cycle
 */
// onMounted event handled
onMounted(() => {
  loadNoti();
  loaded.value = true;
})

// detect profile is updated by store
watchEffect(() => {
  memberAvatar.value = store.getters.currentUser.avatar;
})


/** 
 * Methods
 */
// toggle member submenu
const clickOutsideHandled = () => {
  showMemberMenuStatus.value = false;
  showNotiStatus.value = false;
}

// toggle member submenu
const toggleSubmenu = () => {
  showMemberMenuStatus.value = !showMemberMenuStatus.value;
  showNotiStatus.value = false;
}

// toggle notification submenu
const toggleNoti = () => {
  showMemberMenuStatus.value = false;
  showNotiStatus.value = !showNotiStatus.value;

  if (showNotiStatus.value && notiUnreadCount.value > 0) {
    updateNotiReadStatus();
  } else {
    closeNotiBubble()
  }
}

// load noti
const loadNoti = async () => {
  await http
        .get('/noti/' + memberId.value, {params: {limit: 5}})
        .then(res => {
          notiData.value = res.data;
          notiData.value.rows.forEach((item) => {
            if (item.read_status == 0) {
              notiUnreadCount.value++;
            }
          });
          // console.log(notiData.value);
        })
}

// update read status
const updateNotiReadStatus = async () => {
  await http.put('/noti/' + memberId.value + '/read');
}

const closeNotiBubble = () => {
  notiData.value.rows.forEach((item) => {
    item.read_status = 1;
  });
  notiUnreadCount.value = 0;
}

const notiGotoTarget = (item) => {
  if(item.path == '#') return;
  
  showNotiStatus.value = false;
  router.push(item.path);
}

const notiGotoListPage = async () => {
  showNotiStatus.value = false;
  router.push('/noti');
}
</script>

<template>
  <!-- 
    Display when member had 'logged in' status 
  -->
  <div 
    class="header__member"
    v-click-outside="clickOutsideHandled"
  >
    <ul>

      <!-- Chat status -->
      <!-- <li class="header__member__item">
        <span href="#">
          <SVGIcon icon="bell" />
        </span>
      </li> -->
      <li 
        class="header__member__item"
        :class="{
          active: notiUnreadCount > 0
        }"
      >
        <a 
          @click.prevent="toggleNoti"
          href="#"
        >
          <SVGIcon icon="bell" />
        </a>

        <div 
          class="header__member__noti"
          :class="{ 
            active : showNotiStatus 
          }"
        >
          <h4 class="noti__head">
            ข้อความแจ้งเตือน
          </h4>
          
          <div
            v-if="notiData.total == 0"
            class="noti__empty"
          >
            ไม่มีข้อความแจ้งเตือน
          </div>
          <div v-else>
            <ul class="noti__list">
              <li
                v-for="noti in notiData.rows"
                :key="noti.noti_id"
              >
                <a href="#"
                  @click.prevent="notiGotoTarget(noti)"
                  class="noti__item"
                  :class="{
                    unread: noti.read_status == 0
                  }"
                >
                  <span v-html="noti.message"></span>
                </a>
              </li>
            </ul>
            <div class="noti__foot">
              <a @click.prevent="notiGotoListPage" href="#">
                ดูทั้งหมด
              </a>
            </div>
          </div>
        </div>
      </li>
      
      <!-- Member profile navigator -->
      <li class="header__member__item">
        <a 
          @click.prevent="toggleSubmenu"
          href="#" 
        >
          <Avatar :src=" memberAvatar " />
        </a>

        <div 
          class="header__member__submenu"
          :class="{ 
            active : showMemberMenuStatus 
          }"
        >
          <Nav 
            mode="header" 
            @click="clickOutsideHandled"
          />
        </div>
      </li>



      <!-- <li 
        v-if="memberRole === 'customer'"
        class="header__member__item"
      >
        <router-link 
          to="/register/customer"
          class="btn btn--border"
        >
          <SVGIcon icon="post" />
          โพสงานใหม่
        </router-link>
      </li> -->
      <!-- <li class="header__member__item">
        <a href="#" >
          <SVGIcon icon="message-dots" />
        </a>
      </li> -->
    </ul>
  </div>
</template>